import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Constants } from "./../constants/constants";

export class AccountSettings {
    allow_zixi_support?: number;
    allow_non_sso_login?: number;
}

@Injectable({
    providedIn: "root"
})
export class SettingsService {
    constructor(private http: HttpClient) {}

    async getSettings(accountDnsPrefix: string): Promise<AccountSettings> {
        const result = await this.http
            .get<{ result: AccountSettings; success: boolean }>(
                Constants.apiUrl + Constants.apiUrls.customers + "/" + accountDnsPrefix + "/settings"
            )
            .toPromise();
        return result.result;
    }

    async setSettings(accountDnsPrefix: string, settings: AccountSettings): Promise<AccountSettings> {
        const result = await this.http
            .put<{ result: AccountSettings; success: boolean }>(
                Constants.apiUrl + Constants.apiUrls.customers + "/" + accountDnsPrefix + "/settings",
                settings
            )
            .toPromise();
        return result.result;
    }
}
