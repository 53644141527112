<app-form-wrapper titleMsg="SSO" [formGroup]="form" [loading]="loading" [isEdit]="isEdit">
    <!-- Name -->
    <app-input-generic-field
        [formControl]="form.controls.name"
        [textFieldName]="'NAME' | translate"
        type="text"
        [isParentFormSubmitted]="submitted"
        [checkDuplicateNames]="ssoNames$ | async"
    ></app-input-generic-field>

    <app-radio-input
        label="{{ 'CONFIGURATION' | translate }}"
        [isParentFormSubmitted]="submitted"
        [inputKeyAndValues]="configTypeTypes"
        [formControl]="configTypeControl"
    >
    </app-radio-input>
    <!-- Discovery URL -->
    <app-input-generic-field
        *ngIf="configTypeControl.value === 'openid_disc'"
        [formControl]="form.controls.openIdConfigURL"
        [textFieldName]="'OPENID_DISCOVERY_URL' | translate"
        type="text"
        [isParentFormSubmitted]="submitted"
    ></app-input-generic-field>

    <!-- Authorization URL -->
    <app-input-generic-field
        *ngIf="configTypeControl.value === 'manual'"
        [formControl]="form.controls.authorizationURL"
        [textFieldName]="'AUTHORIZATION_URL' | translate"
        type="text"
        [isParentFormSubmitted]="submitted"
        [underLabel]="isEdit ? ('BLANK_SECRET_EDIT' | translate) : ''"
    ></app-input-generic-field>

    <!-- Token URL -->
    <app-input-generic-field
        *ngIf="configTypeControl.value === 'manual'"
        [formControl]="form.controls.tokenURL"
        [textFieldName]="'TOKEN_URL' | translate"
        type="text"
        [isParentFormSubmitted]="submitted"
        [underLabel]="isEdit ? ('BLANK_SECRET_EDIT' | translate) : ''"
    ></app-input-generic-field>

    <!-- User Info URL -->
    <app-input-generic-field
        *ngIf="configTypeControl.value === 'manual'"
        [formControl]="form.controls.userInfoURL"
        [textFieldName]="'USER_INFO_URL' | translate"
        type="text"
        [isParentFormSubmitted]="submitted"
    ></app-input-generic-field>

    <!-- Client ID -->
    <app-input-generic-field
        textFieldName="{{ 'CLIENT' | translate }} {{ 'ID' | translate }}"
        type="text"
        [formControl]="form.controls.clientID"
        [isParentFormSubmitted]="submitted"
        [underLabel]="isEdit ? ('BLANK_SECRET_EDIT' | translate) : ''"
    ></app-input-generic-field>

    <!-- Client Secret -->
    <app-input-generic-field
        textFieldName="{{ 'CLIENT' | translate }} {{ 'SECRET' | translate }}"
        type="text"
        [formControl]="form.controls.clientSecret"
        [isParentFormSubmitted]="submitted"
        [underLabel]="isEdit ? ('BLANK_SECRET_EDIT' | translate) : ''"
    ></app-input-generic-field>

    <!-- Only pre-approved -->
    <app-checkbox-field
        [label]="'SSO_ONLY_PREAPPROVED' | translate"
        [formControl]="form.controls.sso_only_preapproved"
        [id]="'preApproved'"
    ></app-checkbox-field>

    <div class="form-group">{{ "CALLBACK_URL" | translate }} - {{ window.location.protocol }}//{{ window.location.hostname }}/auth/oauth2/callback</div>
    <app-error [marginBottom]="true" [marginTop]="false"></app-error>

    <app-zx-form-bottom-buttons
        [isFormValid]="form.valid"
        [(submitted)]="submitted"
        (submit)="onSubmit()"
        [isHorizontalRowNeeded]="true"
        [cancelUrlNavigate]="constants.urls.accountManagement.sso"
        [isSaving]="saving"
        [isEdit]="isEdit"
    ></app-zx-form-bottom-buttons>
</app-form-wrapper>
