import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import { UsersService } from "../../users/users.service";
import { KeyMap, SsoConfig } from "../../../../models/shared";

import { SSOsService } from "../ssos.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";

@Component({
    selector: "app-sso-list",
    templateUrl: "./sso-list.component.html"
})
export class SSOListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;
    ssos: SsoConfig[];
    selectedRows: Array<SsoConfig> = [];

    isAdmin: boolean;
    urls = Constants.urls;

    private ssosSubscription: Subscription;
    private ssosBS$ = new BehaviorSubject<SsoConfig[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SsoConfig>>(
                (row: KeyMap<SsoConfig>): string => row?.name,
                (row: KeyMap<SsoConfig>): string => row?.name,
                (): boolean => true
            ),
            sortBy: (row: SsoConfig): string => row?.name,
            textValue: (row: SsoConfig): string => row?.name
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 60,
            visible: true,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<SsoConfig, Promise<void>>({
                canEditCallBack: () => true,
                canDeleteCallBack: () => true,
                editRef: row => this.edit(row),
                deleteRef: row => this.delete(row)
            })
        }
    ];

    constructor(
        private router: Router,
        private ssoService: SSOsService,
        private modalService: ModalService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.userService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        this.ssosSubscription = this.ssoService.ssos.subscribe(ssos => {
            this.ssos = ssos;
            if (this.ssos) {
                this.prepTableData();
                this.loading = false;
            }
        });
    }

    ngOnDestroy() {
        this.ssosSubscription.unsubscribe();
    }

    async refresh() {
        this.refreshing = true;

        const ssos = this.ssoService.refreshSSOs().toPromise();
        await Promise.all([ssos]);

        this.refreshing = false;
    }

    async multiAction(action: string, func: (sso: SsoConfig) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "SSO", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple SSOs");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (sso: SsoConfig) => this.ssoService.deleteSSO(sso));
    }

    edit(sso: SsoConfig): void {
        this.router.navigate([Constants.urls.accountManagement.sso, sso.id, "edit"]);
    }

    async delete(sso: SsoConfig) {
        await this.modalService.confirm(
            "DELETE",
            "SSO",
            async () => {
                const id = sso.id;
                const result = this.ssoService.deleteSSO(sso);
                if (result) {
                    this.mixpanelService.sendEvent("delete sso", { id });
                    this.ssoService.refreshSSOs();
                }
                return result;
            },
            sso.name
        );
    }

    get ssos$() {
        return this.ssosBS$.asObservable();
    }

    private prepTableData() {
        if (this.ssos) {
            this.ssosBS$.next([...this.ssos]);
        }
    }
}
