<!-- Title Bar -->
<div class="title-bar">
    <div class="title">
        <h1 title="{{ 'NAVIGATION.SSO' | translate }}">{{ "NAVIGATION.SSO" | translate }}</h1>
    </div>
</div>
<!-- Tabs -->
<div class="tab-container with-router">
    <div class="tabset">
        <ul ngbNav [(activeId)]="activeTab" #nav="ngbNav" class="justify-content-start nav nav-tabs">
            <li [ngbNavItem]="1" id="aws-accounts-list-tab">
                <a ngbNavLink class="nav-link" title="{{ 'SSO' | translate }}">{{ "SSO" | translate }}</a>
                <ng-template ngbNavContent>
                    <router-outlet></router-outlet>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" id="aws-accounts-doc-tab">
                <a ngbNavLink class="nav-link" title="{{ 'SETTINGS' | translate }}">{{ "SETTINGS" | translate }}</a>
                <ng-template ngbNavContent>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col mt-3">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="zixiSupportAccess"
                                            name="zixiSupportAccess"
                                            [(ngModel)]="accountSettings.allow_zixi_support"
                                            (change)="setSettings()"
                                        />
                                        <label class="form-check-label" for="zixiSupportAccess">{{ "ALLOW_ZIXI_SUPPORT" | translate }} </label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="nonSSOLogin"
                                            name="nonSSOLogin"
                                            [disabled]="isNonSSOLoginCheckboxDisabled"
                                            [(ngModel)]="accountSettings.allow_non_sso_login"
                                            (change)="setSettings()"
                                        />
                                        <label class="form-check-label" for="nonSSOLogin">{{ "ALLOW_NON_SSO_LOGIN" | translate }} </label>
                                    </div>
                                </div>
                                <div class="form-group disabled" *ngIf="freshSettings" translate>AUTO_SAVE_NOTE</div>
                                <div class="form-group disabled" *ngIf="!freshSettings && savingSettings">{{ "SAVING" | translate }}...</div>
                                <div class="form-group disabled" *ngIf="!freshSettings && !savingSettings" translate>{{ "SAVED" | translate }}!</div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>
