import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../../modules/shared/shared.module";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { SSOsComponent } from "./ssos.component";
import { SSOFormComponent } from "./sso-form/sso-form.component";
import { SSOListComponent } from "./sso-list/sso-list.component";

// Routes
import { SSOsRouting } from "./ssos.routing";

@NgModule({
    imports: [CommonModule, FormsModule, NgSelectModule, NgbModule, FontAwesome, SharedModule, SSOsRouting],
    declarations: [SSOsComponent, SSOFormComponent, SSOListComponent]
})
export class SSOsModule {}
