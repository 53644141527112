import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { SSOsService } from "../ssos.service";
import { SsoConfig } from "../../../../models/shared";

@Injectable({
    providedIn: "root"
})
export class SSOListResolverService implements Resolve<SsoConfig[]> {
    constructor(private tps: SSOsService) {}

    resolve(): Observable<SsoConfig[]> | Observable<never> {
        return new Observable((observe: Subscriber<SsoConfig[]>) => {
            this.tps.refreshSSOs().subscribe((ssos: SsoConfig[]) => {
                observe.next(ssos);
                observe.complete();
            });
        });
    }
}
